import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Contact us | Neon Nostalgia Bar & Lounge
			</title>
			<meta name={"description"} content={"Relive the '80s glow at Neon Nostalgia Bar, where vintage vibes meet modern nights"} />
			<meta property={"og:title"} content={"Contact us | Neon Nostalgia Bar & Lounge"} />
			<meta property={"og:description"} content={"Relive the '80s glow at Neon Nostalgia Bar, where vintage vibes meet modern nights"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/logo_1.jpg?v=2023-10-24T12:33:06.409Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<meta name={"msapplication-TileColor"} content={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
		</Helmet>
		<Components.Header />
		<Section background="--color-darkL2" padding="80px 0 80px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Contact Us{"  "}
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
					This is just a glimpse of the treasures that await you at Neon Nostalgia Bar & Lounge. For detailed menu options, dietary requirements, or any special needs contact us.
				</Text>
				<Link
					href="mailto:blank?info@odasnatai.com"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					info@odasnatai.com
				</Link>
				<Link
					href="tel:+1 619-642-0303"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					+1 619-642-0303
				</Link>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--light" text-align="center">
					1872 Rosecrans St, San Diego, CA 92106, United States
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652fe240cd81ee001f7dd8c4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});